import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import MultiChoiceLogo from './static/multichoice.svg';
import DstvLogo from './static/dstv-svg.svg';

import {
  Dashboard,
  View,
  DownloadsView,
  MetricsView,
  LoginView,
  ErrorView,
  NoMatchView,
  HomeView
} from '@4sure/dashboard-core';

import {
  WipIcon as WL,
  DownloadsIcon as DL,
  PerformanceIcon as PL,
  Logo
} from '@4sure/ui-kit';

import * as serviceWorker from './serviceWorker';
import { store } from './state';
// ################
// Telemetry

const isProduction = process.env.NODE_ENV === 'production';
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (isProduction && sentryDsn) {
  Sentry.init({ dsn: sentryDsn });
}
// END Telemetry
// ################

const StyledIcon = IconComponent => styled(IconComponent).attrs({
  height: 26,
  width: 26
})`
  margin: ${props => props.theme.spacing(1)}px;
`;
const LogoWrapper = styled.div`
  padding-top: ${props => props.theme.spacing(3)}px;

  img {
    height: ${props => props.height || 12}px;
    margin: auto;
  }
`;

const DLogo = () => (
  <LogoWrapper>
    <Logo logoSrc={DstvLogo} />
  </LogoWrapper>
);

const MCLogo = () => (
  <LogoWrapper height={54}>
    <Logo logoSrc={MultiChoiceLogo} />
  </LogoWrapper>
);

const WipIcon = StyledIcon(WL);
const DownloadsIcon = StyledIcon(DL);
const PerformanceIcon = StyledIcon(PL);

const LoginPage = styled(LoginView).attrs(() => ({
  subtitle: 'Connected Services Installer Application - CSIA',
  poweredBy: true,
  SubLogo: <DLogo />,
  MainLogo: <MCLogo />
}))``;

if (isProduction && sentryDsn) {
  Sentry.init({ dsn: sentryDsn });
}

// Instantiate the Redux store, including all
// reducers, middleware, enhancers, etc.

ReactDOM.render(
  <>
    <Dashboard store={store}>
      <View pathFragment="/metrics" protectedRoute={true}>
        <View
          pathFragment="/wip"
          component={
            <MetricsView
              boardName={'metric'}
              metricsToLoad={[
                'appointments_for_the_day',
                'appointments_met',
                'missed_appointments',
                'might_miss',
                'en_route',
                'pending',
                'jobs_in_payment_state',
                'jobs_in_qa_state'
              ]}
              pageTitle={'WIP Metrics'}
              pageDescription={'Gain insights into your business with 4-Sure.'}
              keywords={['metrics', 'business Wip']}
              resolution={'month to date'}
            />
          }
          title="WIP"
          tags={['metrics', 'graphs', 'wip']}
          subSection="metrics"
          icon={WipIcon}
        />

        <View
          pathFragment="/performance"
          component={
            <MetricsView
              boardName={'metric'}
              metricsToLoad={[
                'total_installations_registered',
                'total_open_installations',
                'unallocated_installations',
                'rescheduled_installations',
                'installations_completed',
                'average_time_to_install',
                'average_time_to_pay',
                'ftir'
              ]}
              pageTitle={'Performance Metrics'}
              pageDescription={'Gain insights into your business with 4-Sure.'}
              keywords={['metrics', 'business performance']}
              resolution={'month to date'}
              renderResolution={true}
            />
          }
          title="Performance"
          tags={['metrics', 'performance']}
          subSection="metrics"
          icon={PerformanceIcon}
        />

        <View
          pathFragment="/daily"
          component={
            <MetricsView
              boardName={'metric'}
              metricsToLoad={[
                'total_installations_registered',
                'appointments_for_the_day',
                'appointments_met',
                'missed_appointments',
                'appointments_met_and_job_completed',
                'appointments_missed_and_job_completed',
                'pending'
              ]}
              pageTitle={'Daily Statistics'}
              pageDescription={'Gain insights into your business with 4-Sure.'}
              keywords={['metrics', 'daily performance']}
              resolution={'month to date'}
              renderResolution={true}
            />
          }
          title="Daily Statistics"
          tags={['WIP', 'performance']}
          subSection="metnrics"
          icon={PerformanceIcon}
        />
      </View>

      <View
        pathFragment="/downloads"
        protectedRoute={true}
        component={<DownloadsView />}
        title="Downloads"
        tags={['Downloads', 'reports']}
        subSection="Reports"
        icon={DownloadsIcon}
      />

      <View pathFragment="/login" component={<LoginPage />} />

      <View pathFragment="/error" component={<ErrorView />} />

      <View pathFragment="/" component={<HomeView />} protectedRoute={true} />
      <View pathFragment="*" component={<NoMatchView />} />
    </Dashboard>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
